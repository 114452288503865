.swiper-slide {
  width: 100% !important;
  height: 0;
}

.swiper-slide.swiper-slide-active {
  height: fit-content !important;
}

.swiper-slide.swiper-slide-next {
  height: 0;
}

.swiper-slide.swiper-slide-prev {
  height: 0;
}

.swiper-pagination-bullet-active {
  background-color: #ffde40;
}
