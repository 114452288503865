:root {
  --border-color-default: #b3b3b3;
  --year-arrow-color-default: #b3b3b3;
  --year-arrow-color-hover: #4615d6;
}

.datePicker {
  border: 1px solid #ff4d63;
  border-radius: 6px;
}
.react-datepicker-wrapper {
  width: 100%;
}
.error-border-datepicker {
  border: 1px solid #e12038 !important;
}

.react-datepicker-ignore-onclickoutside,
.react-datepicker__input-container input {
  border: none;
  padding: 1.3rem 1.4rem;
  width: inherit;
  height: 40px;
  border: 1px solid #adb8c4;
  border-radius: 6px;
  background-color: #fff;
  font-size: 14px;
  background-image: url("../../../assets/icons/custom-icons/icon-calendar-light.svg");
  background-position: 97% 50%;
  background-repeat: no-repeat;
  background-origin: padding-box;
}

.react-datepicker-ignore-onclickoutside::placeholder,
.react-datepicker__input-container input::placeholder {
  color: #aab4bf;
}

.react-datepicker-popper {
  position: absolute;
  right: 15rem;
  width: fit-content;
  z-index: 10;
}

.react-datepicker__triangle {
  left: 10rem !important;
}

.react-datepicker {
  margin-left: 5rem;
}

.react-datepicker__current-month {
  font-size: 2rem;
}

.react-datepicker__day-name {
  width: 3.5rem;
  height: 3.5rem;
}

.react-datepicker__day {
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
}

.react-datepicker__navigation {
  /*border: 1rem solid transparent;*/
}

.react-datepicker__navigation-icon {
  /*font-size: 30px;*/
}

.react-datepicker__header {
  margin-right: 0 !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  width: 12px;
  height: 12px;
}

.react-datepicker__day {
  font-size: 1.4rem;
}

.react-datepicker__year-read-view--selected-year {
  font-size: 1.6rem;
  margin: 0.5rem 0;
}

.react-datepicker__day-name {
  font-size: 1.6rem;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  margin: 0.5rem 0;
}

.react-datepicker__year-option {
  font-size: 1.4rem;
}

.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  left: -38px;
}

.react-datepicker__navigation--previous {
  border-right-color: var(--border-color-default);
}

.react-datepicker__navigation--next {
  border-left-color: var(--border-color-default);
}

/* Scrollable Year datepicker options here */

.react-datepicker__year-option {
  height: 3rem;
  line-height: 3rem;
}

/* up and down arrow containers */
/* Arrow up container */
.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
  border: 1.5rem solid transparent;
  border-bottom-color: var(--year-arrow-color-default);
  transform: translateY(-1rem);
  position: sticky;
  top: 0;
}

/* Arrow up */
.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming:hover {
  border: 1.5rem solid transparent;
  border-bottom-color: var(--year-arrow-color-hover);
  transform: translateY(-1rem);
}

/* Arrow down container */
.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
  border: 1.5rem solid transparent;
  border-top-color: var(--year-arrow-color-default);
  transform: translateY(1rem);
  position: sticky;
  bottom: 0;
}

/* Arrow down */
.react-datepicker__navigation.react-datepicker__navigation--years.react-datepicker__navigation--years-previous:hover {
  border-top-color: var(--year-arrow-color-hover);
}
